import React, { useEffect } from "react";
import { useRouteError } from "react-router-dom";
import Box from "../../atoms/Box";
import SocialImage from "../../../assets/images/404/social.jpg";
import ShadowImg from "../../atoms/ShadowImg/ShadowImg";
import Button from "../../atoms/Button";
import { EventEmitter } from "../../../helper/JumboEventEmitter";

const ErrorBoundary = () => {
  const error = useRouteError();

  useEffect(() => {
    //Error Logs
    emitEvent("WEB_APP_CRASH");
  }, []);

  //Reload Screen
  function tryAgain() {
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }

  function emitEvent(type) {
    const errorObject = {
      message: error?.message || "",
      errorLogs: error?.statusText || "",
    };
    //SEND LOG
    EventEmitter(type, errorObject.message, errorObject.errorLogs);
  }

  return (
    <>
      <Box className="row">
        <Box className="container">
          <Box
            className="dft"
            height="100vh"
            justifyContent="center"
            flexDirection="column"
            p="20px"
          >
            <Box className="r ov" width="275px" height="200px" mb="40px">
              <ShadowImg src={SocialImage} alt="Error" />
            </Box>
            <Box as="h3" className="f3 f22" pb="10px">
              Oops! That’s an error.
            </Box>
            <Box as="p" className="f20 o2">
              Try reloading the page. We are working hard to fix for you as soon
              as possible.
            </Box>
            <Box pt="20px">
              <Button onClick={tryAgain}>Try Again</Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ErrorBoundary;
