import {
  CLEAR_USER_INFO,
  COMMON_CONFIG,
  DEGREE_LIST,
  OCCUPATION_LIST,
  SUBSCRIPTION_PLAN,
  TOGGLE_APP_LOADER,
  UPDATE_COMMON_CONFIG,
  UPDATE_COUNTRIES_LIST,
  UPDATE_LOGIN_USER_INFO,
} from "./appConfig.constants";

const initialState = {
  userDetails: null,
  countriesList: [{ name: "India", id: "1", dialCode: "+91" }],
  appLoader: false,
  subscriptions: null,
  appConfig: null,
  occupationList: null,
  degreeList: null,
};

const AppConfigReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_LOGIN_USER_INFO:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          ...action.value,
        },
      };
    case UPDATE_COUNTRIES_LIST:
      return {
        ...state,
        countriesList: action.value,
      };
    case CLEAR_USER_INFO:
      return initialState;
    case TOGGLE_APP_LOADER:
      return {
        ...state,
        appLoader: action.value,
      };
    case SUBSCRIPTION_PLAN:
      return {
        ...state,
        subscriptions: action.value,
      };
    case COMMON_CONFIG:
      return {
        ...state,
        appConfig: {
          ...state.appConfig,
          ...action.value,
        },
      };
    case UPDATE_COMMON_CONFIG:
      return {
        ...state,
        appConfig: {
          ...state.appConfig,
          ...action.value,
        },
      };
    case OCCUPATION_LIST:
      return {
        ...state,
        occupationList: action.value,
      };
    case DEGREE_LIST:
      return {
        ...state,
        degreeList: action.value,
      };
    default:
      return state;
  }
};

export default AppConfigReducer;
