export const SmallViewProfileMenuIcon = ({ cls = "" }) => {
  return (
    <svg width="12px" height="12px" viewBox="0 0 15 15" className={cls}>
      <path
        d="M3.85 5.15a.5.5 0 0 0-.7.7l4.35 4.36 4.35-4.36a.5.5 0 1 0-.7-.7L7.5 8.79 3.85 5.15z"
        fillRule="evenodd"
      ></path>
    </svg>
  );
};

export const SmallCameraIcon = ({ height = "24", width = "24", ...props }) => {
  return (
    <svg height={height} viewBox="0 0 24 24" width={width} {...props}>
      <path d="M0 0h24v24H0z" fill="none"></path>
      <circle cx="12" cy="12" r="3.2"></circle>
      <path d="M9 2L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2h-3.17L15 2H9zm3 15c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z"></path>
    </svg>
  );
};

export const SmallUserIcon = ({
  width = "30px",
  height = "30px",
  ...props
}) => {
  return (
    <svg viewBox="0 0 24 24" height={height} width={width} {...props}>
      <path d="M12 6c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2m0 9c2.7 0 5.8 1.29 6 2v1H6v-.99c.2-.72 3.3-2.01 6-2.01m0-11C9.79 4 8 5.79 8 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 9c-2.67 0-8 1.34-8 4v3h16v-3c0-2.66-5.33-4-8-4z" />
    </svg>
  );
};

export const SmallEducationIcon = ({
  height = "24",
  width = "24",
  ...props
}) => {
  return (
    <svg height={height} viewBox="0 96 960 960" width={width} {...props}>
      <path d="M479 936 189 777V537L40 456l439-240 441 240v317h-60V491l-91 46v240L479 936Zm0-308 315-172-315-169-313 169 313 172Zm0 240 230-127V573L479 696 249 571v170l230 127Zm1-240Zm-1 74Zm0 0Z" />
    </svg>
  );
};

export const SmallFamilyIcon = ({
  width = "30px",
  height = "30px",
  ...props
}) => {
  return (
    <svg height={height} viewBox="0 96 960 960" width={width} {...props}>
      <path d="M51 652q-26-43-38.5-86.5T0 480q0-110 77-187t187-77q63 0 119.5 26t96.5 71q40-45 96.5-71T696 216q110 0 187 77t77 187q0 42-12.5 85T909 651q-10-12-22.5-20.5T860 616q20-35 30-69t10-67q0-85-59.5-144.5T696 276q-55 0-108.5 32.5T480 407q-54-66-107.5-98.5T264 276q-85 0-144.5 59.5T60 480q0 33 10 67t30 69q-14 6-26.5 15T51 652ZM0 976v-53q0-39 42-63t108-24q13 0 24 .5t22 2.5q-8 17-12 34.5t-4 37.5v65H0Zm240 0v-65q0-65 66.5-105T480 766q108 0 174 40t66 105v65H240Zm540 0v-65q0-20-3.5-37.5T765 839q11-2 22-2.5t23-.5q67 0 108.5 24t41.5 63v53H780ZM480 826q-80 0-130 24t-50 61v5h360v-6q0-36-49.5-60T480 826Zm-330-20q-29 0-49.5-20.5T80 736q0-29 20.5-49.5T150 666q29 0 49.5 20.5T220 736q0 29-20.5 49.5T150 806Zm660 0q-29 0-49.5-20.5T740 736q0-29 20.5-49.5T810 666q29 0 49.5 20.5T880 736q0 29-20.5 49.5T810 806Zm-330-70q-50 0-85-35t-35-85q0-51 35-85.5t85-34.5q51 0 85.5 34.5T600 616q0 50-34.5 85T480 736Zm0-180q-25 0-42.5 17T420 616q0 25 17.5 42.5T480 676q26 0 43-17.5t17-42.5q0-26-17-43t-43-17Zm0 60Zm0 300Z" />
    </svg>
  );
};

export const SmallPartnerIcon = ({
  width = "24px",
  height = "24px",
  ...props
}) => {
  return (
    <svg viewBox="0 -960 960 960" height={height} width={width} {...props}>
      <path d="M718-313 604-426l57-56 57 56 141-141 57 56-198 198ZM440-501Zm0 381L313-234q-72-65-123.5-116t-85-96q-33.5-45-49-87T40-621q0-94 63-156.5T260-840q52 0 99 22t81 62q34-40 81-62t99-22q81 0 136 45.5T831-680h-85q-18-40-53-60t-73-20q-51 0-88 27.5T463-660h-46q-31-45-70.5-72.5T260-760q-57 0-98.5 39.5T120-621q0 33 14 67t50 78.5q36 44.5 98 104T440-228q26-23 61-53t56-50l9 9 19.5 19.5L605-283l9 9q-22 20-56 49.5T498-172l-58 52Z" />
    </svg>
  );
};

export const SmallDeleteIcon = ({
  width = "30px",
  height = "30px",
  ...props
}) => {
  return (
    <svg viewBox="0 96 960 960" height={height} width={width} {...props}>
      <path d="M261 936q-24.75 0-42.375-17.625T201 876V306h-41v-60h188v-30h264v30h188v60h-41v570q0 24-18 42t-42 18H261Zm438-630H261v570h438V306ZM367 790h60V391h-60v399Zm166 0h60V391h-60v399ZM261 306v570-570Z" />
    </svg>
  );
};

export const SmallPhotosIcon = ({
  width = "24px",
  height = "24px",
  ...props
}) => {
  return (
    <svg viewBox="0 0 24 24" height={height} width={width} {...props}>
      <path d="M18 20H4V6h9V4H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-9h-2v9zm-7.79-3.17l-1.96-2.36L5.5 18h11l-3.54-4.71zM20 4V1h-2v3h-3c.01.01 0 2 0 2h3v2.99c.01.01 2 0 2 0V6h3V4h-3z" />
    </svg>
  );
};

export const SmallVerifyedIcon = ({
  width = "20",
  height = "20",
  ...props
}) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      height={height}
      width={width}
      {...props}
    >
      <path
        d="M15.998 2.596a4.376 4.376 0 0 0-8.03.078 4.375 4.375 0 0 0-5.562 5.918 4.375 4.375 0 0 0-.264 7.671 4.375 4.375 0 0 0 5.872 5.17 4.376 4.376 0 0 0 7.934.08 4.375 4.375 0 0 0 5.69-5.127 4.375 4.375 0 0 0-.25-7.891 4.375 4.375 0 0 0-5.39-5.899Z"
        fill="#3995E0"
      ></path>
      <mask id="Verified_svg__a" fill="#fff">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.6 9.333 16.25 8l-5.418 5.357-3.085-3.05L6.4 11.641 10.809 16l.024-.024.024.024L17.6 9.333Z"
        ></path>
      </mask>
      <path
        d="m16.251 8 .703-.711-.703-.695-.703.695.703.711ZM17.6 9.333l.703.711.72-.71-.72-.712-.703.711Zm-6.767 4.024-.703.711.703.696.703-.696-.703-.71Zm-3.085-3.05.704-.71-.704-.696-.703.695.703.711ZM6.4 11.641l-.703-.711-.72.71.72.712.703-.711ZM10.809 16l-.703.711.703.695.703-.695-.703-.711Zm.024-.024.703-.711-.703-.695-.703.695.703.711Zm.024.024-.703.711.703.695.703-.695-.703-.711Zm4.691-7.289 1.349 1.333 1.406-1.422-1.349-1.333-1.406 1.422Zm-4.012 5.357 5.418-5.357-1.406-1.422-5.418 5.357 1.406 1.422Zm-4.49-3.05 3.084 3.05 1.406-1.422-3.084-3.05-1.407 1.423Zm.057 1.334 1.349-1.333-1.407-1.423-1.348 1.334 1.406 1.422Zm4.409 2.937-4.409-4.36-1.406 1.423 4.409 4.36 1.406-1.423Zm-1.382-.024-.024.024 1.406 1.422.024-.024-1.406-1.422Zm1.43.024-.024-.024-1.406 1.422.024.024 1.406-1.422Zm5.337-6.667-6.743 6.667 1.406 1.422 6.743-6.667-1.406-1.422Z"
        fill="#fff"
        mask="url(#Verified_svg__a)"
      ></path>
    </svg>
  );
};

export const SmallConnectdIcon = ({
  width = "20",
  height = "20",
  ...props
}) => {
  return (
    <svg viewBox="0 -960 960 960" height={height} width={width} {...props}>
      <path d="M480-71.87q-84.913 0-159.345-32.117-74.432-32.118-129.491-87.177-55.059-55.059-87.177-129.491Q71.869-395.087 71.869-480t32.118-159.345q32.118-74.432 87.177-129.491 55.059-55.059 129.491-87.177Q395.087-888.131 480-888.131q65 0 123 18.522t107.478 52.044l-66.369 67.369q-36.566-22.087-77.494-34.51Q525.687-797.13 480-797.13q-131.807 0-224.469 92.543Q162.87-612.043 162.87-480t92.661 224.587q92.66 92.543 224.467 92.543 131.806 0 224.469-92.543Q797.13-347.957 797.13-480q0-16.484-1.76-32.969-1.761-16.484-5.283-32.053l74.326-74.326q11.478 32.718 17.598 67.544 6.12 34.826 6.12 71.7 0 85.017-32.118 159.449-32.118 74.432-87.177 129.491-55.059 55.059-129.491 87.177Q564.913-71.869 480-71.869Zm-56.717-219.347L249.696-464.804l62.456-62.696 111.131 111.13 402.152-403.152 62.696 62.696-464.848 465.609Z" />
    </svg>
  );
};

export const DeclineIcon = ({ width = "20", height = "20", ...props }) => {
  return (
    <svg viewBox="0 -960 960 960" height={height} width={width} {...props}>
      <path d="M338.63-280 480-421.37 621.37-280 680-338.63 538.63-480 680-621.37 621.37-680 480-538.63 338.63-680 280-621.37 421.37-480 280-338.63 338.63-280ZM480-71.87q-84.913 0-159.345-32.117-74.432-32.118-129.491-87.177-55.059-55.059-87.177-129.491Q71.869-395.087 71.869-480t32.118-159.345q32.118-74.432 87.177-129.491 55.059-55.059 129.491-87.177Q395.087-888.131 480-888.131t159.345 32.118q74.432 32.118 129.491 87.177 55.059 55.059 87.177 129.491Q888.131-564.913 888.131-480t-32.118 159.345q-32.118 74.432-87.177 129.491-55.059 55.059-129.491 87.177Q564.913-71.869 480-71.869Zm0-91q133.043 0 225.087-92.043Q797.13-346.957 797.13-480t-92.043-225.087Q613.043-797.13 480-797.13t-225.087 92.043Q162.87-613.043 162.87-480t92.043 225.087Q346.957-162.87 480-162.87ZM480-480Z" />
    </svg>
  );
};

export const SmallViewIcon = ({ width = "20", height = "20", ...props }) => {
  return (
    <svg viewBox="0 0 24 24" height={height} width={width} {...props}>
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M12 6c3.79 0 7.17 2.13 8.82 5.5C19.17 14.87 15.79 17 12 17s-7.17-2.13-8.82-5.5C4.83 8.13 8.21 6 12 6m0-2C7 4 2.73 7.11 1 11.5 2.73 15.89 7 19 12 19s9.27-3.11 11-7.5C21.27 7.11 17 4 12 4zm0 5c1.38 0 2.5 1.12 2.5 2.5S13.38 14 12 14s-2.5-1.12-2.5-2.5S10.62 9 12 9m0-2c-2.48 0-4.5 2.02-4.5 4.5S9.52 16 12 16s4.5-2.02 4.5-4.5S14.48 7 12 7z" />
    </svg>
  );
};

export const SmallShortListIcon = ({
  width = "20",
  height = "20",
  ...props
}) => {
  return (
    <svg viewBox="0 0 24 24" height={height} width={width} {...props}>
      <path d="M22,9.67A1,1,0,0,0,21.14,9l-5.69-.83L12.9,3a1,1,0,0,0-1.8,0L8.55,8.16,2.86,9a1,1,0,0,0-.81.68,1,1,0,0,0,.25,1l4.13,4-1,5.68A1,1,0,0,0,6.9,21.44L12,18.77l5.1,2.67a.93.93,0,0,0,.46.12,1,1,0,0,0,.59-.19,1,1,0,0,0,.4-1l-1-5.68,4.13-4A1,1,0,0,0,22,9.67Zm-6.15,4a1,1,0,0,0-.29.88l.72,4.2-3.76-2a1.06,1.06,0,0,0-.94,0l-3.76,2,.72-4.2a1,1,0,0,0-.29-.88l-3-3,4.21-.61a1,1,0,0,0,.76-.55L12,5.7l1.88,3.82a1,1,0,0,0,.76.55l4.21.61Z"></path>
    </svg>
  );
};

export const ActiveShortListIcon = ({
  width = "20",
  height = "20",
  ...props
}) => {
  return (
    <svg
      viewBox="0 0 18 16"
      fill="none"
      height={height}
      width={width}
      {...props}
    >
      <path
        d="M17.333 6.058a.833.833 0 0 0-.716-.558l-4.742-.692L9.75.5a.833.833 0 0 0-1.5 0L6.125 4.8l-4.742.7a.833.833 0 0 0-.675.567.833.833 0 0 0 .209.833l3.441 3.333-.833 4.734a.833.833 0 0 0 1.208.891L9 13.633l4.25 2.234c.117.066.249.1.383.1a.833.833 0 0 0 .78-.527.833.833 0 0 0 .045-.465l-.833-4.733 3.442-3.334a.833.833 0 0 0 .266-.85Z"
        fill="currentColor"
      ></path>
    </svg>
  );
};

export const SmallBlockIcon = ({ width = "20", height = "20", ...props }) => {
  return (
    <svg viewBox="0 0 24 24" height={height} width={width} {...props}>
      <path d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,0,1-8-8A7.92,7.92,0,0,1,5.69,7.1L16.9,18.31A7.92,7.92,0,0,1,12,20Zm6.31-3.1L7.1,5.69A7.92,7.92,0,0,1,12,4a8,8,0,0,1,8,8A7.92,7.92,0,0,1,18.31,16.9Z"></path>
    </svg>
  );
};

export const SmallUsersIcon = ({ width = "25", height = "25", ...props }) => {
  return (
    <svg viewBox="0 96 960 960" height={height} width={width} {...props}>
      <path d="M38 896v-94q0-35 18-63.5t50-42.5q73-32 131.5-46T358 636q62 0 120 14t131 46q32 14 50.5 42.5T678 802v94H38Zm700 0v-94q0-63-32-103.5T622 633q69 8 130 23.5t99 35.5q33 19 52 47t19 63v94H738ZM358 575q-66 0-108-42t-42-108q0-66 42-108t108-42q66 0 108 42t42 108q0 66-42 108t-108 42Zm360-150q0 66-42 108t-108 42q-11 0-24.5-1.5T519 568q24-25 36.5-61.5T568 425q0-45-12.5-79.5T519 282q11-3 24.5-5t24.5-2q66 0 108 42t42 108ZM98 836h520v-34q0-16-9.5-31T585 750q-72-32-121-43t-106-11q-57 0-106.5 11T130 750q-14 6-23 21t-9 31v34Zm260-321q39 0 64.5-25.5T448 425q0-39-25.5-64.5T358 335q-39 0-64.5 25.5T268 425q0 39 25.5 64.5T358 515Zm0 321Zm0-411Z" />
    </svg>
  );
};

export const SmallNotificationIcon = ({
  width = "22",
  height = "22",
  fill,
  ...props
}) => {
  return (
    <svg viewBox="0 0 24 24" height={height} width={width} {...props}>
      <path
        fillRule="evenodd"
        d="M18 15.75v-5c0-3.07-1.63-5.64-4.5-6.32V2.25h-3v2.18C7.64 5.11 6 7.67 6 10.75v5l-2 2v1h16v-1l-2-2Zm-6 6c1.1 0 2-.9 2-2h-4c0 1.1.9 2 2 2Zm-4-5h8v-6c0-2.48-1.51-4.5-4-4.5s-4 2.02-4 4.5v6Z"
        fill={fill || "#768493"}
      ></path>
    </svg>
  );
};

export const SmallContactIcon = ({ width = "25", height = "25", ...props }) => {
  return (
    <svg viewBox="0 96 960 960" height={height} width={width} {...props}>
      <path d="M500 236v-60h380v60H500Zm0 170v-60h380v60H500Zm0 170v-60h380v60H500Zm255 400q-122 0-242.5-60T296 760q-96-96-156-216.5T80 301q0-19.286 12.857-32.143T125 256h140q13.611 0 24.306 9.5Q300 275 303 291l27 126q2 14-.5 25.5T319 462L219 563q56 93 125.5 162T502 842l95-98q10-11 23-15.5t26-1.5l119 26q15.312 3.375 25.156 15.188Q800 780 800 796v135q0 19.286-12.857 32.143T755 976ZM189 508l81-82-23-110H140q0 39 12 85.5T189 508Zm369 363q41 19 89 31t93 14V809l-103-21-79 83ZM189 508Zm369 363Z" />
    </svg>
  );
};

export const SmallChatIcon = ({ width = "20", height = "20", ...props }) => {
  return (
    <svg viewBox="0 0 24 24" height={height} width={width} {...props}>
      <path d="M4 4h16v12H5.17L4 17.17V4m0-2c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2H4zm2 10h8v2H6v-2zm0-3h12v2H6V9zm0-3h12v2H6V6z" />
    </svg>
  );
};

export const ReceivedAcceptedIcon = ({
  width = "24",
  height = "24",
  ...props
}) => {
  return (
    <svg viewBox="0 0 24 24" height={height} width={width} {...props}>
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M16 9h-2.55V6h-2.9v3H8l4 4zm3-6H4.99C3.88 3 3 3.9 3 5v14c0 1.1.88 2 1.99 2H19c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5v-3h3.56c.69 1.19 1.97 2 3.45 2s2.75-.81 3.45-2H19v3zm0-5h-4.99c0 1.1-.9 2-2 2s-2-.9-2-2H5l-.01-9H19v9z" />
    </svg>
  );
};

export const SentAcceptedIcon = ({ width = "24", height = "24", ...props }) => {
  return (
    <svg viewBox="0 -960 960 960" height={height} width={width} {...props}>
      <path d="M440-400v-166l-64 64-56-58 160-160 160 160-56 58-64-64v166h-80ZM200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-120H640q-30 38-71.5 59T480-240q-47 0-88.5-21T320-320H200v120Zm280-120q38 0 69-22t43-58h168v-360H200v360h168q12 36 43 58t69 22ZM200-200h560-560Z" />
    </svg>
  );
};

export const SmallChangeProfilePictureIcon = ({
  width = "30px",
  height = "30px",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
      height={height}
      width={width}
      {...props}
    >
      <path d="M539.882-480Q589-480 623.5-514.382q34.5-34.383 34.5-83.5Q658-647 623.618-681.5q-34.383-34.5-83.5-34.5Q491-716 456.5-681.618q-34.5 34.383-34.5 83.5Q422-549 456.382-514.5q34.383 34.5 83.5 34.5ZM260-259q51-65 124-103t156-38q83 0 156 38t124 103v-561H260v561Zm0 59q-24.75 0-42.375-17.625T200-260v-560q0-24.75 17.625-42.375T260-880h560q24.75 0 42.375 17.625T880-820v560q0 24.75-17.625 42.375T820-200H260ZM140-80q-24.75 0-42.375-17.625T80-140v-570h60v570h570v60H140Zm400-460q-24 0-41-17t-17-41q0-24 17-41t41-17q24 0 41 17t17 41q0 24-17 41t-41 17ZM353-260h374q-36-39-84.5-59.5T540-340q-54 0-102.5 20.5T353-260Zm187-280Z" />
    </svg>
  );
};

export const SmallCareerIcon = ({
  width = "24px",
  height = "24px",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
      height={height}
      width={width}
      {...props}
    >
      <path d="M140-120q-24 0-42-18t-18-42v-480q0-24 18-42t42-18h180v-100q0-24 18-42t42-18h200q24 0 42 18t18 42v100h180q24 0 42 18t18 42v480q0 24-18 42t-42 18H140Zm240-600h200v-100H380v100Zm440 349H571v60H391v-60H140v191h680v-191Zm-369 0h60v-60h-60v60Zm-311-60h251v-60h180v60h249v-229H140v229Zm340 30Z" />
    </svg>
  );
};

export const SmallKundliIcon = ({
  width = "24px",
  height = "24px",
  ...props
}) => {
  return (
    <svg viewBox="0 0 24 24" height={height} width={width} {...props}>
      <rect fill="none" height="24" width="24" />
      <path d="M12,2C6.48,2,2,6.48,2,12s4.48,10,10,10h8c1.1,0,2-0.9,2-2v-8C22,6.48,17.52,2,12,2z M12,20c-2.89,0-5.43-1.54-6.83-3.84 l2.95-2.95L11.41,16L16,11.42V13h2V8h-5v2h1.58l-3.28,3.28L8,10.5l-3.69,3.7C4.11,13.5,4,12.76,4,12c0-4.41,3.59-8,8-8s8,3.59,8,8 S16.41,20,12,20z M19.5,20.5c-0.55,0-1-0.45-1-1s0.45-1,1-1s1,0.45,1,1S20.05,20.5,19.5,20.5z" />
    </svg>
  );
};

export const SmallInterestsIcon = ({
  width = "24px",
  height = "24px",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
      height={height}
      width={width}
      {...props}
    >
      <path d="m604-389 40-145-124-85-40 145 124 85ZM195-160l-66-27q-32.17-14.026-43.585-48.013Q74-269 92-302l103-245v387Zm142 58q-33.825 0-57.913-24Q255-150 255-184v-313l137 368q3 8 6.5 14t9.5 13h-71Zm183-25q-23 8-45.932-2.203Q451.137-139.407 443-162L251-684q-8-23 2.441-45.875Q263.881-752.75 287-761l319-115q23-8 46.5 2t31.5 33l193 521q8 23-2.203 45.875Q864.593-251.25 842-243L520-127Zm-20-57 318-116-191-519-318 115 191 520Zm64-318Z" />
    </svg>
  );
};

export const BackIcon = ({ width = "24px", height = "24px", ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      height={height}
      width={width}
      {...props}
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" />
    </svg>
  );
};

export const PendingIcon = ({ width = "24", height = "24", ...props }) => {
  return (
    <svg
      viewBox="0 0 24 24"
      enableBackground="new 0 0 24 24"
      height={height}
      width={width}
      {...props}
    >
      <g>
        <rect fill="none" height="24" width="24" />
        <path d="M17,12c-2.76,0-5,2.24-5,5s2.24,5,5,5c2.76,0,5-2.24,5-5S19.76,12,17,12z M18.65,19.35l-2.15-2.15V14h1v2.79l1.85,1.85 L18.65,19.35z M18,3h-3.18C14.4,1.84,13.3,1,12,1S9.6,1.84,9.18,3H6C4.9,3,4,3.9,4,5v15c0,1.1,0.9,2,2,2h6.11 c-0.59-0.57-1.07-1.25-1.42-2H6V5h2v3h8V5h2v5.08c0.71,0.1,1.38,0.31,2,0.6V5C20,3.9,19.1,3,18,3z M12,5c-0.55,0-1-0.45-1-1 c0-0.55,0.45-1,1-1c0.55,0,1,0.45,1,1C13,4.55,12.55,5,12,5z" />
      </g>
    </svg>
  );
};

export const DeclinedIcon = ({ width = "24", height = "24", ...props }) => {
  return (
    <svg viewBox="0 0 24 24" height={height} width={width} {...props}>
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M20.99 14.04V5c0-1.1-.9-2-2-2H5c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h10.05c.28 1.92 2.1 3.35 4.18 2.93 1.34-.27 2.43-1.37 2.7-2.71.25-1.24-.16-2.39-.94-3.18zm-2-9.04L12 8.5 5 5h13.99zm-3.64 10H5V7l7 3.5L19 7v6.05c-.16-.02-.33-.05-.5-.05-1.39 0-2.59.82-3.15 2zm5.15 2h-4v-1h4v1z" />
    </svg>
  );
};

export const HomeIcon = ({ width = "22px", height = "22px", ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      height={height}
      width={width}
      {...props}
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M12 5.69l5 4.5V18h-2v-6H9v6H7v-7.81l5-4.5M12 3L2 12h3v8h6v-6h2v6h6v-8h3L12 3z" />
    </svg>
  );
};
export const ActiveHomeIcon = ({
  width = "22px",
  height = "22px",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      height={height}
      width={width}
      {...props}
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    </svg>
  );
};

export const ActivityIcon = ({ width = "22px", height = "22px", ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      height={height}
      width={width}
      {...props}
    >
      <g>
        <rect fill="none" height="24" width="24" x="0" />
      </g>
      <g>
        <g>
          <path d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M12,20c-4.41,0-8-3.59-8-8s3.59-8,8-8s8,3.59,8,8 S16.41,20,12,20z M12.5,7H11v6l5.2,3.2l0.8-1.3l-4.5-2.7V7z" />
        </g>
      </g>
    </svg>
  );
};
export const ActiveActivityIcon = ({
  width = "22px",
  height = "22px",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      height={height}
      width={width}
      {...props}
    >
      <g>
        <rect fill="none" height="24" width="24" />
      </g>
      <g>
        <g>
          <g>
            <path d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M16.2,16.2L11,13V7h1.5v5.2l4.5,2.7L16.2,16.2z" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export const SearchIcon = ({ width = "24px", height = "24px", ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      height={height}
      width={width}
      {...props}
    >
      <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
    </svg>
  );
};

export const MessengerIcon = ({
  width = "24px",
  height = "24px",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      height={height}
      width={width}
      {...props}
    >
      <path d="M12,2A10,10,0,0,0,2,12a9.89,9.89,0,0,0,2.26,6.33l-2,2a1,1,0,0,0-.21,1.09A1,1,0,0,0,3,22h9A10,10,0,0,0,12,2Zm0,18H5.41l.93-.93a1,1,0,0,0,0-1.41A8,8,0,1,1,12,20Z"></path>
    </svg>
  );
};
export const FillMessengerIcon = ({
  width = "24px",
  height = "24px",
  ...props
}) => {
  return (
    <svg viewBox="0 0 24 24" height={height} width={width} {...props}>
      <path d="M12 2A10 10 0 0 0 2 12a9.89 9.89 0 0 0 2.26 6.33l-2 2a1 1 0 0 0-.21 1.09A1 1 0 0 0 3 22h9a10 10 0 0 0 0-20Z"></path>
    </svg>
  );
};

export const CopyIcon = ({ width = "22", height = "22", ...props }) => {
  return (
    <svg height={height} width={width} {...props} viewBox="0 -960 960 960">
      <path d="M180-81q-24 0-42-18t-18-42v-603h60v603h474v60H180Zm120-120q-24 0-42-18t-18-42v-560q0-24 18-42t42-18h440q24 0 42 18t18 42v560q0 24-18 42t-42 18H300Zm0-60h440v-560H300v560Zm0 0v-560 560Z" />
    </svg>
  );
};

export const NextIcon = ({ width = "22", height = "22", ...props }) => {
  return (
    <svg height={height} width={width} {...props} viewBox="0 0 24 24">
      <path d="M7.38 21.01c.49.49 1.28.49 1.77 0l8.31-8.31c.39-.39.39-1.02 0-1.41L9.15 2.98c-.49-.49-1.28-.49-1.77 0s-.49 1.28 0 1.77L14.62 12l-7.25 7.25c-.48.48-.48 1.28.01 1.76z" />
    </svg>
  );
};

export const ShareIcon = ({ width = "24", height = "24", ...props }) => {
  return (
    <svg
      viewBox="0 0 32 32"
      height={height}
      width={width}
      {...props}
      style={{ stroke: "currentcolor", strokeWidth: "3" }}
    >
      <g fill="none">
        <path d="M27 18v9a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-9M16 3v23V3zM6 13l9.3-9.3a1 1 0 0 1 1.4 0L26 13"></path>
      </g>
    </svg>
  );
};

export const MoreIcon = ({ width = "22", height = "22", ...props }) => {
  return (
    <svg height={height} width={width} {...props} viewBox="0 0 24 24">
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
    </svg>
  );
};

export const SmallCloseIcon = ({ width = "22", height = "22", ...props }) => {
  return (
    <svg viewBox="0 0 24 24" height={height} width={width} {...props}>
      <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
    </svg>
  );
};

export const SmallFacebookIcon = ({
  width = "30",
  height = "30",
  ...props
}) => {
  return (
    <svg viewBox="0 0 24 24" height={height} width={width} {...props}>
      <rect fill="none" height="24" width="24"></rect>
      <path d="M22,12c0-5.52-4.48-10-10-10S2,6.48,2,12c0,4.84,3.44,8.87,8,9.8V15H8v-3h2V9.5C10,7.57,11.57,6,13.5,6H16v3h-2 c-0.55,0-1,0.45-1,1v2h3v3h-3v6.95C18.05,21.45,22,17.19,22,12z"></path>
    </svg>
  );
};

export const SmallYouTubeIcon = ({ width = "30", height = "30", ...props }) => {
  return (
    <svg viewBox="0 0 24 24" height={height} width={width} {...props}>
      <path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"></path>
    </svg>
  );
};

export const SmallInstagramIcon = ({
  width = "30",
  height = "30",
  ...props
}) => {
  return (
    <svg viewBox="0 -960 960 960" height={height} width={width} {...props}>
      <path d="M200-120q-33 0-56.5-23.5T120-200v-160h80v160h160v80H200Zm400 0v-80h160v-160h80v160q0 33-23.5 56.5T760-120H600ZM120-600v-160q0-33 23.5-56.5T200-840h160v80H200v160h-80Zm640 0v-160H600v-80h160q33 0 56.5 23.5T840-760v160h-80ZM480-280q-83 0-141.5-58.5T280-480q0-83 58.5-141.5T480-680q83 0 141.5 58.5T680-480q0 83-58.5 141.5T480-280Zm0-80q50 0 85-35t35-85q0-50-35-85t-85-35q-50 0-85 35t-35 85q0 50 35 85t85 35Zm0-120Z" />
    </svg>
  );
};

export const InfoIcon = ({ width = "24", height = "24", ...props }) => {
  return (
    <svg viewBox="0 0 24 24" height={height} width={width} {...props}>
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
    </svg>
  );
};

export const SettingsIcon = ({ width = "24", height = "24", ...props }) => {
  return (
    <svg viewBox="0 0 24 24" height={height} width={width} {...props}>
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M19.43 12.98c.04-.32.07-.64.07-.98 0-.34-.03-.66-.07-.98l2.11-1.65c.19-.15.24-.42.12-.64l-2-3.46c-.09-.16-.26-.25-.44-.25-.06 0-.12.01-.17.03l-2.49 1c-.52-.4-1.08-.73-1.69-.98l-.38-2.65C14.46 2.18 14.25 2 14 2h-4c-.25 0-.46.18-.49.42l-.38 2.65c-.61.25-1.17.59-1.69.98l-2.49-1c-.06-.02-.12-.03-.18-.03-.17 0-.34.09-.43.25l-2 3.46c-.13.22-.07.49.12.64l2.11 1.65c-.04.32-.07.65-.07.98 0 .33.03.66.07.98l-2.11 1.65c-.19.15-.24.42-.12.64l2 3.46c.09.16.26.25.44.25.06 0 .12-.01.17-.03l2.49-1c.52.4 1.08.73 1.69.98l.38 2.65c.03.24.24.42.49.42h4c.25 0 .46-.18.49-.42l.38-2.65c.61-.25 1.17-.59 1.69-.98l2.49 1c.06.02.12.03.18.03.17 0 .34-.09.43-.25l2-3.46c.12-.22.07-.49-.12-.64l-2.11-1.65zm-1.98-1.71c.04.31.05.52.05.73 0 .21-.02.43-.05.73l-.14 1.13.89.7 1.08.84-.7 1.21-1.27-.51-1.04-.42-.9.68c-.43.32-.84.56-1.25.73l-1.06.43-.16 1.13-.2 1.35h-1.4l-.19-1.35-.16-1.13-1.06-.43c-.43-.18-.83-.41-1.23-.71l-.91-.7-1.06.43-1.27.51-.7-1.21 1.08-.84.89-.7-.14-1.13c-.03-.31-.05-.54-.05-.74s.02-.43.05-.73l.14-1.13-.89-.7-1.08-.84.7-1.21 1.27.51 1.04.42.9-.68c.43-.32.84-.56 1.25-.73l1.06-.43.16-1.13.2-1.35h1.39l.19 1.35.16 1.13 1.06.43c.43.18.83.41 1.23.71l.91.7 1.06-.43 1.27-.51.7 1.21-1.07.85-.89.7.14 1.13zM12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z" />
    </svg>
  );
};

export const FavoriteIcon = ({ width = "24", height = "24", ...props }) => {
  return (
    <svg viewBox="0 -960 960 960" height={height} width={width} {...props}>
      <path d="m480-120-58-52q-101-91-167-157T150-447.5Q111-500 95.5-544T80-634q0-94 63-157t157-63q52 0 99 22t81 62q34-40 81-62t99-22q94 0 157 63t63 157q0 46-15.5 90T810-447.5Q771-395 705-329T538-172l-58 52Z" />
    </svg>
  );
};

export const NextArrowIcon = ({ width = "24", height = "24", ...props }) => {
  return (
    <svg height={height} width={width} {...props} viewBox="0 0 24 24">
      <path d="M17.92,11.62a1,1,0,0,0-.21-.33l-5-5a1,1,0,0,0-1.42,1.42L14.59,11H7a1,1,0,0,0,0,2h7.59l-3.3,3.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l5-5a1,1,0,0,0,.21-.33A1,1,0,0,0,17.92,11.62Z"></path>
    </svg>
  );
};

export const DoneIcon = ({ width = "24", height = "24", ...props }) => {
  return (
    <svg height={height} width={width} {...props} viewBox="0 0 24 24">
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" />
    </svg>
  );
};

export const VisibilityIcon = ({ width = "24", height = "24", ...props }) => {
  return (
    <svg height={height} width={width} {...props} viewBox="0 -960 960 960">
      <path d="m644-428-58-58q9-47-27-88t-93-32l-58-58q17-8 34.5-12t37.5-4q75 0 127.5 52.5T660-500q0 20-4 37.5T644-428Zm128 126-58-56q38-29 67.5-63.5T832-500q-50-101-143.5-160.5T480-720q-29 0-57 4t-55 12l-62-62q41-17 84-25.5t90-8.5q151 0 269 83.5T920-500q-23 59-60.5 109.5T772-302Zm20 246L624-222q-35 11-70.5 16.5T480-200q-151 0-269-83.5T40-500q21-53 53-98.5t73-81.5L56-792l56-56 736 736-56 56ZM222-624q-29 26-53 57t-41 67q50 101 143.5 160.5T480-280q20 0 39-2.5t39-5.5l-36-38q-11 3-21 4.5t-21 1.5q-75 0-127.5-52.5T300-500q0-11 1.5-21t4.5-21l-84-82Zm319 93Zm-151 75Z" />
    </svg>
  );
};

export const NoPhotography = ({ width = "24", height = "24", ...props }) => {
  return (
    <svg viewBox="0 -960 960 960" height={height} width={width} {...props}>
      <path d="m880-195-80-80v-405H638l-73-80H395l-38 42-57-57 60-65h240l74 80h126q33 0 56.5 23.5T880-680v485Zm-720 75q-33 0-56.5-23.5T80-200v-480q0-33 23.5-56.5T160-760h41l80 80H160v480h601l80 80H160Zm466-215q-25 34-62.5 54.5T480-260q-75 0-127.5-52.5T300-440q0-46 20.5-83.5T375-586l58 58q-24 13-38.5 36T380-440q0 42 29 71t71 29q29 0 52-14.5t36-38.5l58 58Zm-18-233q25 24 38.5 57t13.5 71v12q0 6-1 12L456-619q6-1 12-1h12q38 0 71 13.5t57 38.5ZM819-28 27-820l57-57L876-85l-57 57ZM407-440Zm171-57Z" />
    </svg>
  );
};

export const PencilIcon = ({ width = "24", height = "24", ...props }) => {
  return (
    <svg viewBox="0 0 24 24" height={height} width={width} {...props}>
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M14.06 9.02l.92.92L5.92 19H5v-.92l9.06-9.06M17.66 3c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29zm-3.6 3.19L3 17.25V21h3.75L17.81 9.94l-3.75-3.75z" />
    </svg>
  );
};

export const PremiumIcon = ({ width = "24px", height = "24px", ...props }) => {
  return (
    <svg viewBox="0 -960 960 960" height={height} width={width} {...props}>
      <path d="m387-412 35-114-92-74h114l36-112 36 112h114l-93 74 35 114-92-71-93 71ZM240-40v-309q-38-42-59-96t-21-115q0-134 93-227t227-93q134 0 227 93t93 227q0 61-21 115t-59 96v309l-240-80-240 80Zm240-280q100 0 170-70t70-170q0-100-70-170t-170-70q-100 0-170 70t-70 170q0 100 70 170t170 70ZM320-159l160-41 160 41v-124q-35 20-75.5 31.5T480-240q-44 0-84.5-11.5T320-283v124Zm160-62Z" />
    </svg>
  );
};

export const FeedbackIcon = ({ width = "24px", height = "24px", ...props }) => {
  return (
    <svg viewBox="0 0 24 24" height={height} width={width} {...props}>
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 14H5.17l-.59.59-.58.58V4h16v12zm-9-4h2v2h-2zm0-6h2v4h-2z" />
    </svg>
  );
};

export const PrivacyIcon = ({ width = "24px", height = "24px", ...props }) => {
  return (
    <svg height={height} width={width} {...props} viewBox="0 0 24 24">
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M12 1L3 5v6c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12V5l-9-4zm0 10.99h7c-.53 4.12-3.28 7.79-7 8.94V12H5V6.3l7-3.11v8.8z" />
    </svg>
  );
};

export const HelpIcon = ({ width = "24px", height = "24px", ...props }) => {
  return (
    <svg height={height} width={width} {...props} viewBox="0 0 24 24">
      <path d="M21,12.22C21,6.73,16.74,3,12,3c-4.69,0-9,3.65-9,9.28C2.4,12.62,2,13.26,2,14v2c0,1.1,0.9,2,2,2h1v-6.1 c0-3.87,3.13-7,7-7s7,3.13,7,7V19h-8v2h8c1.1,0,2-0.9,2-2v-1.22c0.59-0.31,1-0.92,1-1.64v-2.3C22,13.14,21.59,12.53,21,12.22z" />
      <circle cx="9" cy="13" r="1" />
      <circle cx="15" cy="13" r="1" />
      <path d="M18,11.03C17.52,8.18,15.04,6,12.05,6c-3.03,0-6.29,2.51-6.03,6.45c2.47-1.01,4.33-3.21,4.86-5.89 C12.19,9.19,14.88,11,18,11.03z" />
    </svg>
  );
};

export const MenuIcon = ({ width = "22", height = "22", ...props }) => {
  return (
    <svg
      viewBox="0 0 32 32"
      stroke="currentcolor"
      strokeWidth="2"
      height={height}
      width={width}
      {...props}
    >
      <g fill="none">
        <circle cx="16" cy="16" r="14"></circle>
        <path d="M14.02 19.66a6 6 0 1 1 3.96 0M17.35 19.67H18c3.69.61 6.8 2.91 8.54 6.08m-20.92-.27A12.01 12.01 0 0 1 14 19.67h.62"></path>
      </g>
    </svg>
  );
};

export const MessageSendIcon = ({
  width = "22px",
  height = "22px",
  ...props
}) => {
  return (
    <svg viewBox="0 0 24 24" height={height} width={width} {...props}>
      <path d="M21.68,17.65l-7-14a3,3,0,0,0-5.36,0l-7,14a3,3,0,0,0,3.9,4.08l5.37-2.4h0a1.06,1.06,0,0,1,.82,0l5.37,2.4a3,3,0,0,0,3.9-4.08Zm-2,2a1,1,0,0,1-1.13.22l-5.37-2.39a3,3,0,0,0-2.44,0L5.41,19.9a1,1,0,0,1-1.3-1.35l7-14a1,1,0,0,1,1.78,0l7,14A1,1,0,0,1,19.72,19.68Z"></path>
    </svg>
  );
};
