import { DEVICE_TYPE } from "../constant/app.constant";
import { getCordovaPlugin, isAndroid } from "./nativehandler";

export function debounce(func, timeout = 1000) {
  let timer;
  return function (...args) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

export function checkIsMobileBrowser() {
  let check = false;
  if (typeof window?.mobileCheck === "boolean") {
    return window?.mobileCheck;
  } else {
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
  }
  //Update
  window.mobileCheck = check;
  return check;
}

export function isMobileDevice() {
  let check = false;
  const browser = window?._deviceInfo?.browser;
  if (
    [DEVICE_TYPE.ANDROID, DEVICE_TYPE.IOS, DEVICE_TYPE.MWEB].includes(browser)
  ) {
    check = true;
  }
  return check;
}

export function getDeviceType() {
  return window?._deviceInfo?.browser || DEVICE_TYPE.WEB;
}

export function cleanData(value) {
  // Deep Copy object to so that actual does not changes
  const newValue = JSON.parse(JSON.stringify(value));
  // iterate newValue and remove empty fields
  (function isEmpty(data) {
    if (typeof data === "object" && data !== null) {
      if (Array.isArray(data)) {
        const indexToRemove = [];
        data.forEach((item, index) => {
          if (isEmpty(item)) {
            indexToRemove.unshift(index);
          }
        });
        indexToRemove.forEach((item) => {
          data.splice(item, 1);
        });
      } else {
        Object.keys(data).forEach((key) => {
          let trimValue = data[key];
          if (trimValue && typeof trimValue === "string") {
            trimValue = trimValue.trim();
          }
          if (isEmpty(trimValue)) {
            delete data[key];
          }
        });
        if (!Object.keys(data).length) {
          return true;
        }
      }
    }
    return data === null || data === undefined || data === "";
  })(newValue);
  return newValue;
}

export function detectionBrowser(nav) {
  try {
    const sUsrAg = nav.userAgent;
    if (sUsrAg.indexOf("Opera") > -1 || sUsrAg.indexOf("OPR") > -1) {
      return "OPERA";
    } else if (sUsrAg.indexOf("Edg") > -1) {
      return "MICROSOFT EDGE";
    } else if (sUsrAg.indexOf("Chrome") > -1) {
      return "CHROME";
    } else if (sUsrAg.indexOf("Safari") > -1) {
      return "SAFARI";
    } else if (sUsrAg.indexOf("Firefox") > -1) {
      return "FIREFOX";
    } else if (sUsrAg.indexOf("SamsungBrowser") > -1) {
      return "SAMSUNG INTERNET";
    } else if (sUsrAg.indexOf("Trident") > -1) {
      return "INTERNET EXPLORER";
    } else {
      return "UNKNOWN";
    }
  } catch (error) {
    return "UNKNOWN";
  }
}

export const feetToInch = (cm) => {
  const realFeet = (cm * 0.3937) / 12;
  const feet = Math.floor(realFeet);
  const inches = Math.round((realFeet - feet) * 12);
  return { feet, inches };
};

export function feetToInchForDropdown(cm, index) {
  const { feet, inches } = feetToInch(cm);
  if (inches === 12) {
    return undefined;
  }
  if (index === 0) {
    return {
      id: `${feet}_${inches}`,
      name: `Below ${feet}ft`,
      value: cm,
      inches,
      feet,
    };
  } else if (feet >= 7) {
    //For Loop Break
    if (inches !== 0) {
      return null;
    }

    return {
      id: `${feet}_${inches}`,
      name: `Above ${feet}ft`,
      value: cm,
      inches,
      feet,
    };
  } else {
    let name = `${feet}ft ${inches}in`;
    if (inches === 0) {
      name = `${feet}ft`;
    }
    return {
      id: `${feet}_${inches}`,
      name: name,
      value: cm,
      inches,
      feet,
    };
  }
}

export const generateheightdropdown = () => {
  let data = [];
  let lastValue = null;
  for (let index = 0; index <= 120; index++) {
    const startCm = 122 + index;
    let feetData = feetToInchForDropdown(startCm, index);
    if (feetData === null) {
      break;
    }
    //Check
    if (feetData && feetData?.id !== lastValue?.id) {
      //Push
      data.push(feetData);
      lastValue = feetData;
    }
  }
  return data;
};

export const getNextPageSerial = (page, limit, total) => {
  return page * limit < total;
};

export const margeOldAndNewData = (response, alreadyPost, filters) => {
  const payload = response?.payload;
  response.payload["hasMore"] = getNextPageSerial(
    filters.page,
    filters.limit,
    payload.total
  );
  if (filters.page > 1) {
    payload["data"] = [
      ...(alreadyPost?.payload?.data || []),
      ...(payload?.data || []),
    ];
  }

  //Return
  return response;
};

export const generateAgedropdown = (count = 18) => {
  let data = [];
  for (let index = count; index <= 60; index++) {
    data.push({ id: index, name: index, value: index });
  }
  return data;
};

export const copyToClipboard = async (copyMe, callback) => {
  try {
    if (isAndroid()) {
      if (getCordovaPlugin("clipboard")) {
        getCordovaPlugin("clipboard")?.copy(copyMe);
        callback && callback({ status: 200, message: "Link copied" });
        return;
      }
      throw Promise.reject("clipboard plugin not found!");
    }

    await navigator.clipboard.writeText(copyMe);
    callback && callback({ status: 200, message: "Link copied" });
  } catch (err) {
    callback && callback({ status: 500, message: "Oops, unable to copy" });
  }
};

export const navigatorShare = async (props, callback) => {
  const { title, text, url } = props;
  if ("share" in navigator) {
    navigator
      .share({
        title: title || "",
        text: text || "",
        url: url || "",
      })
      .then(() => {
        callback && callback(true);
      })
      .catch((err) => {
        callback && callback(false, props);
      });
  } else {
    callback && callback(false, props);
  }
};

export function objectToConverter(sendData) {
  const base64 = btoa(JSON.stringify(sendData));
  return (base64 || '').replace(/=+$/, '');
}
export function base64ToObjectConvert(encodedData) {
  try {
    const data = window.atob(encodedData);
    return JSON.parse(data);
  } catch (error) {
    return {};
  }
}

/**
 * Convert json object to queryParams.
 * 
 * @param {Object} jsonObj Json object.
 * @returns return queryParams string;
 */
export function jsonObjToQueryParams (jsonObj) {
  if (!jsonObj) return '';
  let queryParams = '';
  const jsonList = Object.entries(jsonObj);
  for (let i = 0;  i < jsonList.length; i++) {
    let [key, value] = jsonList[i];
    queryParams += `${key}=${value}`;
    if (jsonList.length > i + 1) {
      queryParams += '&';
    }
  }
  return queryParams;
}
