import React, { useEffect, useRef } from "react";
import Box from "../Box";
// import NoPhoto from "../../../assets/images/no-photo.jpg";
import "./style.scss";

const ShadowImg = ({ src, cls = "", defaultImg = "0", alt, isLazy = true }) => {
  const imageRef = useRef(null);
  const shadowRef = useRef(null);
  let obs = null;

  useEffect(() => {
    canLazyLoad() ? lazyLoadImage() : loadImage();
    if (!isLazy) {
      loadImage();
    }
    //disconnect
    return () => {
      if (obs) {
        obs.disconnect();
      }
    };
  }, [obs, src]);

  function canLazyLoad() {
    let sWindow = window;
    return sWindow && "IntersectionObserver" in sWindow;
  }

  function lazyLoadImage() {
    if (!src) {
      return;
    }
    obs = new IntersectionObserver((entries) => {
      entries.forEach(({ isIntersecting }) => {
        if (isIntersecting) {
          loadImage();
          obs.unobserve(imageRef.current);
        }
      });
    });
    obs.observe(imageRef.current);
  }

  function loadImage() {
    if (imageRef.current) {
      imageRef.current.src = src;
    }
  }

  function onErrorHandler(e) {
    e.target.style.display = "none";
  }

  return (
    <Box className={`ov ${cls}`} ref={shadowRef}>
      {src && (
        <img
          className="_shadowImage"
          alt={alt || "img"}
          ref={imageRef}
          onError={onErrorHandler}
        />
      )}
      <Box position="absolute" top="0" left="0" right="0" bottom="0"></Box>
    </Box>
  );
};

export default ShadowImg;
